/**
 *@file Products.tsx
 *@brief Product component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import React from 'react';
import { Products } from '../interfaces/GeneralInterface';
import './Products.scss';
import NoImage from '../assets/img/no-image.svg';

interface Product extends Products {}

const Product = React.forwardRef((props: Product, ref: any) => {
  return (
    <div onClick={props.onClick} className="product-wrap">
      <div className="image-wrap">
        <img src={props?.image ?? NoImage} alt="" />
      </div>
      <div className="dtls-wrap">
        <h5>{props.title}</h5>
        {props?.price && <span className="price d-inline-block">${props?.price}</span>}
      </div>
    </div>
  );
});

export default Product;
