/**
 *@file Category.tsx
 *@brief Category component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import React from 'react';
import { Category } from '../interfaces/GeneralInterface';
import './Category.scss';

interface ICategory extends Category { }

const CategoryDtls = React.forwardRef((props: ICategory, ref: any) => {
  return (
    <div className={'item-category ' + (props.products ? 'products' : '') + (props.services ? 'services' : '') + (props.food ? 'food' : '') + (props.thingstodo ? 'thingstodo' : '') + (props.classifieds ? 'classifieds' : '')}>
      <span>{props.category}</span> - {props.item}
    </div>
  );
});

export default CategoryDtls;
