// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer{border-top:#d7e1e8 solid 1px;height:80px;color:#777}`, "",{"version":3,"sources":["webpack://./src/components/Footer.scss"],"names":[],"mappings":"AASA,OACI,4BAAA,CACA,WAAA,CACA,UAAA","sourcesContent":["/**\n *@file Footer.scss\n *@brief Footer component style\n *@date September, 2023\n *@author ZCO Engineer\n *@copyright (c) 2023, ZCO\n */\n@import \"../assets/styles/variables.scss\";\n\nfooter{\n    border-top: #D7E1E8 solid 1px;\n    height: 80px;\n    color: #777777;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
