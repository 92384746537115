export enum loginTypes {
  APPLELOGIN = 'apple',
  FACEBOOKLOGIN = 'facebook',
  GUESTLOGIN = 'guest',
}

export enum categoryIds {
  All = 0,
  PRODUCTS = 1,
  FOOD = 2,
  SERVICES = 3,
  THINGSTODO = 4,
  CLASSIFIEDS = 5,
}

export enum changePwd {
  CURRENT_PWD = 'the Current Password',
}
export enum error_code {
  Code_0 = 0,
  Code_1 = 1,
  Code_2 = 2,
  Code_3 = 3,
  Code_13 = 13,
}
