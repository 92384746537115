/**
 * @file   src\containers\Profile.tsx
 * @brief  Profile page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from 'react';
import { Button, Col, Container, Row, Modal, Form } from 'react-bootstrap';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import Strings from '../assets/strings/Strings.json';
import ProfileImg from '../assets/img/user-image.png';
import '../assets/styles/profile.scss';
import NBInputGroup from '../components/NBInputGroup';
import NBPhoneInput from '../components/NBPhoneInputGroup';
import Camera from '../assets/img/icons/Camera';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteUser, getProfile, editProfile } from '../store/actions/authAction';
import { profileImageUpload, userLocationUpdate } from '../store/actions/userAction';
import { resetProfileImageUploadState, } from '../store/slices/userSlice';
import { resetDeleteUserState } from '../store/slices/authSlice';
import { useAppSelector } from '../hooks/index';
import * as CONSTANT from '../utils/constants';
import { toast } from 'react-toastify';
import { validateImageTypes } from '../utils/authHelper';
import { UserProfile } from 'src/interfaces/user';
import * as alerts from '../utils/alerts'
import { emailValidation, nameValidation, validatePhoneNo } from '../utils/authHelper';
import { IProfileData, IProfileDataError } from '../interfaces/GeneralInterface'
import { getUser } from '../utils/localStorage';
import { usePlacesWidget } from "react-google-autocomplete";

const Profile = () => {
  const user_info = JSON.parse(getUser());

  const intitalValuesProfileData = {
    f_name: '',
    l_name: '',
    mob_number: '',
    e_address: '',
    u_address: '',
    u_address2: '',
    u_city: '',
    u_state: '',
    u_zip: '',
    state_name: '',
    u_code: '',
    country: 'usa',
    login_type: user_info?.u_logintype ? user_info?.u_logintype : 1,
  };
  const intitalValuesProfileError = {
    f_nameError: '',
    l_nameError: '',
    mob_numberError: '',
    e_addressError: '',
    u_addressError: '',
    u_address2: '',
    u_cityError: '',
    u_stateError: '',
    state_nameError: '',
    u_codeError: '',
  };

  const refAddress = useRef(null);
  const [images, setImages] = React.useState<any>(null);
  const [editable, setEditable] = React.useState<boolean>(false);
  const [profileData, setProfileData] = React.useState<IProfileData>(intitalValuesProfileData);
  const [initialProfileData, setInitialProfileData] = React.useState<IProfileData>(intitalValuesProfileData);
  const [productPicError, setProductPicError] = React.useState<any>('');
  const [loadMap, setLoadMap] = useState<boolean>(false);
  const [place, setPlace] = useState<any>(null);
  const [profileError, setprofileError] = useState<IProfileDataError>(intitalValuesProfileError);
  const profileImageUploadResponse = useAppSelector((RootReducer) => RootReducer.user.profileImageUpload);
  const [load, setLoad] = useState<boolean>(false);
  const [lat, setLat] = useState<string>('');
  const [long, setLong] = useState<string>('');

  // handle image upload
  const onChange = (imageList: ImageListType) => {
    const img = imageList[0]?.file;
    const isValid = validateImageTypes(img);
    if (isValid === '') {
      setLoad(true)
      const formData = new FormData();
      formData.append('fileName', img);
      dispatch<any>(profileImageUpload(formData));
    } else {
      setProductPicError(isValid);
    }
  };
  const [deleteConfirmationShow, setDeleteConfirmationShow] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // handle delete account api call.
  const deleteUserAccount = () => {
    dispatch<any>(deleteUser());
  };

  // handle get profile information api call.
  const profileInfo = () => {
    dispatch<any>(getProfile());
  };

  // handle edit profile api call.
  const editprofile = (params: any) => {
    dispatch<any>(editProfile(params));
  };

  // Account delete confirmation modal show.
  const deleteAccntConfirmation = () => {
    setDeleteConfirmationShow(true);
  };

  // Account delete confirmation modal close.
  const deleteAccntConfirmationClose = () => {
    setDeleteConfirmationShow(false);
  };

  // handle profile image response.
  useEffect(() => {
    if (profileImageUploadResponse.isLoading === false && profileImageUploadResponse.isSuccess === true) {
      setLoad(false)
      if (profileImageUploadResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setImages(profileImageUploadResponse?.userInfo?.profile_image);
      } else {
        toast.error(profileImageUploadResponse?.errorMessage);
      }
      dispatch<any>(resetProfileImageUploadState());
    }
  }, [profileImageUploadResponse]);

  const deleteUserResponse = useAppSelector((RootReducer) => RootReducer.auth.deleteuser);
  const editProfileResponse = useAppSelector((RootReducer) => RootReducer.auth.profileEdit);
  const profileResponse = useAppSelector((RootReducer) => RootReducer.auth.profile);

  // handle intial profile api call.
  useEffect(() => {
    setProfileData(intitalValuesProfileData);
    profileInfo();
  }, []);

  // handle user delete api response.
  useEffect(() => {
    if (deleteUserResponse.isLoading === false && deleteUserResponse.isSuccess === true) {
      setDeleteConfirmationShow(false);
      if (deleteUserResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        navigate('/signin');
        toast.success(deleteUserResponse?.errorMessage);
      } else {
        toast.error(deleteUserResponse?.errorMessage);
      }
      dispatch<any>(resetDeleteUserState());
    }
  }, [deleteUserResponse]);

  // handel profile api response data.
  useEffect(() => {
    if (profileResponse.isLoading === false && profileResponse.isSuccess === true) {
      if (profileResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setProfileData((profileData) => ({
          ...profileData,
          f_name: profileResponse.userInfo.user_info.f_name ? profileResponse.userInfo.user_info.f_name : '',
          l_name: profileResponse.userInfo.user_info.l_name ? profileResponse.userInfo.user_info.l_name : '',
          mob_number: profileResponse.userInfo.user_info.mob_number ? profileResponse.userInfo.user_info.mob_number : '',
          e_address: profileResponse.userInfo.user_info.e_address ? profileResponse.userInfo.user_info.e_address : '',
          u_address: profileResponse.userInfo.user_info.u_address1 ? profileResponse.userInfo.user_info.u_address1 : '',
          u_address2: profileResponse.userInfo.user_info.u_address2 ? profileResponse.userInfo.user_info.u_address2 : '',
          u_city: profileResponse.userInfo.user_info.u_city ? profileResponse.userInfo.user_info.u_city : '',
          state_name: profileResponse.userInfo.user_info.u_state ? profileResponse.userInfo.user_info.u_state : '',
          u_code: profileResponse.userInfo.user_info.u_zip ? profileResponse.userInfo.user_info.u_zip : '',
        }));
        setInitialProfileData((initialProfileData) => ({
          ...initialProfileData,
          f_name: profileResponse.userInfo.user_info.f_name ? profileResponse.userInfo.user_info.f_name : '',
          l_name: profileResponse.userInfo.user_info.l_name ? profileResponse.userInfo.user_info.l_name : '',
          mob_number: profileResponse.userInfo.user_info.mob_number ? profileResponse.userInfo.user_info.mob_number : '',
          e_address: profileResponse.userInfo.user_info.e_address ? profileResponse.userInfo.user_info.e_address : '',
          u_address: profileResponse.userInfo.user_info.u_address1 ? profileResponse.userInfo.user_info.u_address1 : '',
          u_address2: profileResponse.userInfo.user_info.u_address2 ? profileResponse.userInfo.user_info.u_address2 : '',
          u_city: profileResponse.userInfo.user_info.u_city ? profileResponse.userInfo.user_info.u_city : '',
          state_name: profileResponse.userInfo.user_info.u_state ? profileResponse.userInfo.user_info.u_state : '',
          u_code: profileResponse.userInfo.user_info.u_zip ? profileResponse.userInfo.user_info.u_zip : '',
        }));
        setImages(profileResponse.userInfo?.user_info?.p_image);
      }
    }
  }, [profileResponse]);

  // handle profile edited response data
  useEffect(() => {
    if (editProfileResponse.isLoading === false && editProfileResponse.isSuccess === true) {
      if (editProfileResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        toast.success(editProfileResponse?.errorMessage);
        setEditable(false);
      } else {
        toast.error(editProfileResponse?.errorMessage);
      }
    }
  }, [editProfileResponse]);

  // // handle google autocomplete loading script
  // useEffect(() => {
  //   if (!loadMap && editable) {
  //     loadGoogleMapScript(() => {
  //       setLoadMap(true)
  //     });
  //   }
  // }, [loadMap, editable]);

  // // handle google autocomplete initialization.
  // useEffect(() => {
  //   if (loadMap && editable) {
  //     initPlaceAPI();
  //   }

  // }, [loadMap, editable]);

  // handle google autocomplete response data
  useEffect(() => {
    if (place) {
      let address: any = place;
      let streetNumber = "";
      let routeInfo = "";
      let plusCode = "";
      let neighborhood = "";
      let premise = "";
      let businessCity = '';
      address.forEach((item: any) => {

        if (item.types.indexOf("street_number") >= 0) {
          streetNumber = item.long_name + " ";
          setProfileData((profileData) => ({
            ...profileData,
            u_address: streetNumber,
          }));
        }

        if (item.types.indexOf("route") >= 0) {
          routeInfo = streetNumber + item.long_name + " ";
          setProfileData((profileData) => ({
            ...profileData,
            u_address: routeInfo,
          }));
        }
        if (item.types.indexOf("plus_code") >= 0) {
          if (streetNumber === "") {
            plusCode = streetNumber + routeInfo + item.long_name + " ";
          } else {
            plusCode = routeInfo + item.long_name + " ";
          }
          setProfileData((profileData) => ({
            ...profileData,
            u_address: plusCode,
          }));
        }

        if (item.types.indexOf("neighborhood") >= 0) {
          if (streetNumber === "") {
            neighborhood = streetNumber + routeInfo + plusCode + item.long_name + " ";
          } else {
            neighborhood = routeInfo + plusCode + item.long_name + " ";
          }
          setProfileData((profileData) => ({
            ...profileData,
            u_address: neighborhood,
          }));
        }
        if (item.types.indexOf("premise") >= 0) {
          if (streetNumber === "") {
            premise =
              streetNumber +
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          } else {
            premise =
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          }
          setProfileData((profileData) => ({
            ...profileData,
            u_address: premise,
          }));
        }
        if (item.types.indexOf("administrative_area_level_1") >= 0) {
          setProfileData((profileData) => ({
            ...profileData,
            state_name: item.short_name,
          }));
        }
        if (item.types.indexOf('locality') >= 0) {
          businessCity = item.short_name;
          setProfileData((profileData) => ({
            ...profileData,
            u_city: item.short_name,
          }));
        } else if (item.types.indexOf('administrative_area_level_3') >= 0 && businessCity === '') {
          setProfileData((profileData) => ({
            ...profileData,
            u_city: item.short_name,
          }));
        }

        if (item.types.indexOf("postal_code") >= 0) {
          setProfileData((profileData) => ({
            ...profileData,
            u_code: item.long_name,
          }));
        }
      });
    }
  }, [place]);

  // // load google map script
  // const loadGoogleMapScript = (callback: any) => {
  //   if (
  //     typeof window.google === "object" &&
  //     typeof window.google.maps === "object"
  //   ) {
  //     callback();
  //   } else {
  //     const googleMapScript = document.createElement("script");
  //     googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}&callback=Function.prototype&libraries=places`;
  //     window.document.body.appendChild(googleMapScript);
  //     googleMapScript.addEventListener("load", callback);
  //     setLoadMap(true);
  //   }
  // };

  // // load google map script
  // const initPlaceAPI = () => {
  //   setTimeout(() => {
  //     if (window.google) {
  //       let autocomplete = new window.google.maps.places.Autocomplete(
  //         refAddress.current,
  //         {
  //           fields: ["address_components", "geometry"],
  //           types: ["address"],
  //         }
  //       );
  //       new window.google.maps.event.addListener(
  //         autocomplete,
  //         "place_changed",
  //         function () {
  //           let place = autocomplete.getPlace();
  //           setProfileData((profileData) => ({
  //             ...profileData,
  //             u_address: '',
  //             u_address2: '',
  //             u_city: '',
  //             state_name: '',
  //             u_code: '',
  //           }));
  //           setPlace(place.address_components);            
  //           setLat(place.geometry.location.lat());
  //           setLong(place.geometry.location.lng());
  //         }
  //       );
  //     }
  //   }, 500);
  // };

  // handle profile onchange data
  const handleProfileChanges = (event: any) => {
    setProfileData((profileData: any) => ({
      ...profileData,
      [event.target.name]: event.target.value,
    }));
  };
  // handle phone change data
  const handlePhoneChanges = (event: string) => {
    setProfileData((profileData: any) => ({
      ...profileData,
      mob_number: event,
    }));
  };

  // handle the profile cancel.
  const handleCancelProfileChanges = () => {
    setProfileData(initialProfileData);
    setEditable(false);
  };

  // Profile to editable state.
  const handleEditProfileChanges = () => {
    setEditable(true);
  };

  // handle phone format
  const phoneFormat = (value: any) => {
    let formattedPhoneNumber = value;
    const fullFromatCheck = /^\(\d{3}\) \d{3}-\d{4}$/;
    const fullFromatCheck2 = /^\(\d{3}\) \d{3} \d{4}$/;
    if (value) {
      if (fullFromatCheck.test(value) && value.length === 14) {
        return value;
      } else if ( fullFromatCheck2.test(value) && value.length === 14) {
        if (value.length > 6) {
          formattedPhoneNumber = `${value.slice(0, 9)} ${value.slice(9, 14)}`;
        }
        return formattedPhoneNumber
      } else {
        if (value.length <= 3) {
          formattedPhoneNumber = value;
        } else if (value.length > 3 && value.length <= 6) {
          formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
        } else if (value.length > 6) {
          formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(
            3,
            6
          )} ${value.slice(6, 10)}`;
        }
        return formattedPhoneNumber
      }
    }
  };


  // validate profile data for errors.
  const validatProfile = () => {
    let isValid = true;
    let validEmail = emailValidation(profileData.e_address)
    const firstNameErrMsg = nameValidation(profileData.f_name);
    const lastNameErrMsg = nameValidation(profileData.l_name);
    const phoneNumberErrMsg = validatePhoneNo(profileData.mob_number);

    if (profileData.f_name.trim() === '') {
      setprofileError((profileError) => ({ ...profileError, ['f_nameError']: alerts.ENTER_FIRST_NAME }));
      isValid = false
    } else if (firstNameErrMsg) {
      setprofileError((profileError) => ({ ...profileError, ['f_nameError']: firstNameErrMsg }));
      isValid = false
    } else {
      setprofileError((profileError) => ({ ...profileError, ['f_nameError']: '' }));
    }

    if (profileData.l_name.trim() === '') {
      setprofileError((profileError) => ({ ...profileError, ['l_nameError']: alerts.ENTER_LAST_NAME }));
      isValid = false
    } else if (lastNameErrMsg) {
      setprofileError((profileError) => ({ ...profileError, ['l_nameError']: lastNameErrMsg }));
      isValid = false
    } else {
      setprofileError((profileError) => ({ ...profileError, ['l_nameError']: '' }));
    }
    if (profileData.mob_number === '') {
      setprofileError((profileError) => ({ ...profileError, ['mob_numberError']: alerts.ENTER_PHONE_NUMBER }));
      isValid = false
    } else {
      setprofileError((profileError) => ({ ...profileError, ['mob_numberError']: '' }));
    }

    if (phoneNumberErrMsg !== '') {
      setprofileError((profileError) => ({ ...profileError, ['mob_numberError']: phoneNumberErrMsg }));
      isValid = false;
    } else {
      setprofileError(() => ({ ...profileError, mob_numberError: '' }));
    }

    if (profileData.e_address.trim() === '') {
      setprofileError((profileError) => ({ ...profileError, ['e_addressError']: alerts.ENTER_EMAIL }));
      isValid = false
    }
    else if (!validEmail) {
      setprofileError((profileError) => ({ ...profileError, ['e_addressError']: alerts.ENTER_VALID_EMAIL }));
      isValid = false
    } else {
      setprofileError((profileError) => ({ ...profileError, ['e_addressError']: '' }));
    }
    return isValid;
  };

  // handle save profile and make api call.
  const saveProfile = () => {
    let valid = validatProfile();
    const removeNullValues = (obj: UserProfile) => {
      const newObj: any = {};
      for (const key in obj) {
        if (obj[key] !== '' || null) {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    };
    if (valid) {
      const payload = {
        f_name: profileData.f_name,
        l_name: profileData.l_name,
        mob_number: phoneFormat(profileData.mob_number),
        e_address: profileData.e_address,
        u_address: profileData.u_address,
        u_address2: profileData.u_address2,
        u_city: profileData.u_city,
        u_state: profileData.u_state,
        u_zip: profileData.u_zip,
        state_name: profileData.state_name,
        u_code: profileData.u_code,
        country: profileData.country,
        login_type: profileData.login_type,
      }
      editprofile(removeNullValues(payload));
      if (lat !== '' && long !== '') {
        const payload = {
          userLocationLat: lat,
          userLocationLon: long,
          userId: user_info && user_info.user_id ? String(user_info?.user_id): '',
          zipCode: profileData.u_code,
          device_token: '',
          deviceType: 0,
        };
        dispatch<any>(userLocationUpdate(payload));
        setLat('');
        setLong('');
      }
    }
  };

  // This will go back to the Home page
  const handleGoBack = () => {
    window.location.href = '/';
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY,
    onPlaceSelected: (place: any) => {
      setProfileData((profileData) => ({
        ...profileData,
        u_address: '',
        u_address2: '',
        u_city: '',
        state_name: '',
        u_code: '',
      }));
      setPlace(place.address_components);
      setLat(place.geometry.location.lat());
      setLong(place.geometry.location.lng());
    },
    options: {
      fields: ['formatted_address', 'address_components', 'geometry', 'name'],
      strictBounds: false,
      types: ['geocode', 'establishment'],
    },
  });
  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container>
          <Row>
            <Col md="6" className="d-flex align-items-center">
              <h2>{Strings.Profile.Title}</h2>
            </Col>
            <Col md="6" className="text-md-end btn-wrap">
              <Button onClick={handleGoBack} variant="secondary">
                {Strings.Profile.Back}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container>
          <div className="profile-pic-nav">
            <ImageUploading value={images} onChange={onChange}>
              {({ imageList, onImageUpload }) => (

                <div className="upload__image-wrapper">
                  {load ?
                    <div className="image-view d-flex align-items-center justify-content-center">
                      <span className="spinner-border" style={{ color: '#94DA0A' }} />
                    </div>
                    :
                    <>
                      <div key={0} className="image-item">
                        <img src={images === null ? ProfileImg : images} alt="" width="120" />
                      </div>
                      <Button disabled={!editable} onClick={onImageUpload} className="btn-icononly">
                        <Camera />
                      </Button>
                    </>
                  }
                </div>
              )}
            </ImageUploading>
            <Form.Text className="mt-1">{productPicError}</Form.Text>
          </div>
          <Col xl={11} md={12}>
            <Row className="profile-form">
              <Col xl={4} lg={5} md={6}>
                <NBInputGroup
                  id="FirstName"
                  name="f_name"
                  type="text"
                  label={Strings.Profile.Form.FirstName}
                  onChange={handleProfileChanges}
                  value={profileData.f_name}
                  maxLength={50}
                  disabled={!editable}
                  error={profileError.f_nameError}
                />
              </Col>
              <Col xl={4} lg={5} md={6}>
                <NBInputGroup
                  id="LatName"
                  name="l_name"
                  type="text"
                  label={Strings.Profile.Form.LastName}
                  onChange={handleProfileChanges}
                  value={profileData.l_name}
                  maxLength={50}
                  disabled={!editable}
                  error={profileError.l_nameError}
                />
              </Col>
              <Col xl={4} lg={5} md={6}>
                <NBPhoneInput
                  id="phone"
                  name="mob_number"
                  placeholder=""
                  label={Strings.Profile.Form.Mobile}
                  onChange={handlePhoneChanges}
                  value={profileData.mob_number}
                  disabled={!editable}
                  error={profileError.mob_numberError}
                />
              </Col>
              <Col xl={4} lg={5} md={6}>
                <NBInputGroup
                  id="Email"
                  name="e_address"
                  type="email"
                  label={Strings.Profile.Form.Email}
                  onChange={handleProfileChanges}
                  maxLength={150}
                  value={profileData.e_address}
                  disabled={true}
                  error={profileError.e_addressError}
                  readOnly={true}
                />
              </Col>
              <Col xl={4} lg={5} md={6}>
                <NBInputGroup
                  id="Address"
                  placeholder={editable ? 'Enter a location' : ""}
                  name="u_address"
                  type="text"
                  label={Strings.Profile.Form.Address1}
                  onChange={handleProfileChanges}
                  value={profileData.u_address}
                  disabled={!editable}
                  ref={ref}
                />
              </Col>
              <Col xl={4} lg={5} md={6}>
                <NBInputGroup
                  id="Address"
                  name="u_address2"
                  type="text"
                  label={Strings.Profile.Form.Address2}
                  value={profileData.u_address2}
                  disabled={!editable}
                  readOnly
                />
              </Col>
              <Col xl={4} lg={5} md={6}>
                <NBInputGroup
                  id="City"
                  name="u_city"
                  type="text"
                  label={Strings.Profile.Form.City}
                  maxLength={25}
                  value={profileData.u_city}
                  disabled={!editable}
                  readOnly
                />
              </Col>
              <Col xl={4} lg={5} md={6}>
                <NBInputGroup
                  id="State"
                  name="state_name"
                  type="text"
                  label={Strings.Profile.Form.State}
                  value={profileData.state_name}
                  disabled={!editable}
                  readOnly
                />
              </Col>
              <Col xl={4} lg={5} md={6}>
                <NBInputGroup
                  id="ZIP"
                  name="u_code"
                  type="text"
                  maxLength={5}
                  label={Strings.Profile.Form.ZipCode}
                  value={profileData.u_code}
                  disabled={!editable}
                  readOnly
                />
              </Col>
            </Row>
            <Col className="button-nav">
              {editable ? (
                <Button variant="secondary" onClick={handleCancelProfileChanges}>
                  {Strings.Profile.Form.Cancel}
                </Button>
              ) : (
                <Button variant="secondary" onClick={deleteAccntConfirmation}>
                  {Strings.Profile.Form.Delete}
                </Button>
              )}
              {!editable ? (
                <Button variant="primary" onClick={handleEditProfileChanges}>
                  {' '}
                  {Strings.Profile.Form.Edit}
                </Button>
              ) : (
                <Button variant="primary" onClick={saveProfile}>
                  {Strings.Profile.Form.Save}
                </Button>
              )}
            </Col>
          </Col>
        </Container>
      </Container>

      <Modal show={deleteConfirmationShow} onHide={deleteAccntConfirmationClose} centered backdrop="static">
        <Modal.Body className="otp-verify">
          <h1 className="text-center">{Strings.Profile.Form.Delete}</h1>
          <p className="text-center pb-1">
            {Strings.Profile.Form.Account.label}
            <br />
          </p>
          <div className="btn-wrap d-flex">
            <Button onClick={deleteAccntConfirmationClose} variant="secondary">
              {Strings.Profile.Form.Account.Btn2}
            </Button>
            <Button onClick={deleteUserAccount} variant="primary">
              {Strings.Profile.Form.Account.Btn1}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Profile;
