/**
 * @file   src\containers\PaymentCardInfo.tsx
 * @brief  Payment Card details page
 * @date   Oct, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from 'react';
import { useState } from 'react';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import { loadStripe } from "@stripe/stripe-js";
import '../assets/styles/profile.scss';
import Card from '../assets/img/card.svg';
import PaymentSuccess from '../assets/img/paymentSuccess.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import PaymentCard from '../components/paymentCard';
import { useAppSelector, useAppDispatch } from '../hooks/index';
import { toast } from 'react-toastify';
import * as CONSTANT from '../utils/constants';
import { resetMakeOrderProductState } from '../store/slices/productSlice'

const PaymentCardInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);

  // handle Modal close and navigate to home
  const handleClose = () => {
    setShow(false)
    navigate('/')
  };

  // handle sucess modal show
  const handleShow = () => {
    setShow(true)
    setLoad(false)
  }
  const location = useLocation();
  const productInfo = location.state?.productInfo;
  const quantity = location.state?.quantity;
  const total = location.state.total;
  const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY : '';
  const stripePromise = loadStripe(stripePublishableKey);
  const options = {
    clientSecret: location.state && location.state.client_secret ? location.state.client_secret : '',
  };
  const productMakeOrderRsponse = useAppSelector((RootReducer) => RootReducer.product.makeOrderProduct);
  const [load, setLoad] = useState(false);

  // handles product make order api response
  React.useEffect(() => {
    if (productMakeOrderRsponse.isLoading === false && productMakeOrderRsponse.isSuccess === true) {
      if (productMakeOrderRsponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        handleShow();
      } else {
        toast.error(productMakeOrderRsponse?.errorMessage);
        setLoad(false);
      }
    }
    dispatch(resetMakeOrderProductState());
  }, [productMakeOrderRsponse]);

  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container>
          <h2>Payment</h2>
        </Container>
      </Container>
      <Container fluid>
        <Container className="content-area">
          <h3>Your Card Information</h3>
          <Col xl={12} md={12}>
            <Row className="profile-form card-info">
              <Col xl={6} lg={12}>
                <div className="card-info">
                  <img src={Card} />
                  <h6>Card</h6>
                </div>
                <Elements stripe={stripePromise} options={options}>
                  <PaymentCard total={total} productInfo={productInfo} quantity={quantity} setLoad={setLoad} load={load} />
                </Elements>
              </Col>
            </Row>
          </Col>
        </Container>
      </Container>

      {/* Payment success */}
      <Modal show={show} onHide={handleClose} centered size="sm">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="pt-3 pb-5 text-center">
          <img src={PaymentSuccess} />
          <h4>Success!</h4>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentCardInfo;
