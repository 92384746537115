const setUser = (User: any) => {
  localStorage.setItem('uInfo', JSON.stringify(User));
};

const setGusetUserTerms = (data: any) => {
  localStorage.setItem('gTerms', JSON.parse(data));
};

const setGusetUser = (data: any) => {
  localStorage.setItem('gInfo', JSON.parse(data));
};

const setSocialUserTerms = (data: any) => {
  localStorage.setItem('sTerms', JSON.parse(data));
};
const setLatLong = (data: any) => {
  localStorage.setItem('sLatLong', JSON.stringify(data));
};

const setNationalWide = (data: any) => {
  localStorage.setItem('sNationalWide', JSON.parse(data));
};
const setCategory = (data: any) => {
  localStorage.setItem('sCategory', JSON.parse(data));
};

const setVendorInfo = (data: any) => {
  localStorage.setItem('sVInfo', JSON.stringify(data));
};
const setFilter = (data: any) => {
  localStorage.setItem('sFilter', JSON.stringify(data));
};

const setSessionOut = (data: any) => {
  localStorage.setItem('sessionOut', JSON.stringify(data));
};

const getLatLong = () => {
  const latLong = localStorage.getItem('sLatLong');

  if (latLong !== null) {
    const session = JSON.parse(latLong);
    return session;
  }
  return null;
};
const getNationalWide = () => {
  const nationalWide = localStorage.getItem('sNationalWide');

  if (nationalWide !== null) {
    const session = JSON.parse(nationalWide);
    return session;
  }
  return null;
};
const getCategory = () => {
  const category = localStorage.getItem('sCategory');

  if (category !== null) {
    const session = JSON.parse(category);
    return session;
  }
  return null;
};
const getVendorInfo = () => {
  const category = localStorage.getItem('sVInfo');

  if (category !== null) {
    const session = JSON.parse(category);
    return session;
  }
  return null;
};


const getGusetUserTerms = () => {
  const userInfo = localStorage.getItem('gTerms');

  if (userInfo !== null) {
    const session = JSON.parse(userInfo);
    return session;
  }
  return null;
};

const getGusetUser = () => {
  const userInfo = localStorage.getItem('gInfo');

  if (userInfo !== null) {
    const session = JSON.parse(userInfo);
    return session;
  }
  return null;
};

const getSocialUserTerms = () => {
  const userInfo = localStorage.getItem('sTerms');

  if (userInfo !== null) {
    const session = JSON.parse(userInfo);
    return session;
  }
  return null;
};

const getUser = () => {
  const userInfo = localStorage.getItem('uInfo');

  if (userInfo !== null) {
    const session = JSON.parse(userInfo);
    return session;
  }
  return null;
};
const getFilter = () => {
  const filterString = localStorage.getItem('sFilter');

  if (filterString !== null) {
    const session = JSON.parse(filterString);
    return session;
  }
  return null;
};

const getAuthToken = () => {
  const userInfo = JSON.parse(localStorage.getItem("uInfo"))
  let aToken = ''
  if (userInfo) {
    if (userInfo.a_token) {
      aToken = userInfo.a_token;
    } else if (JSON.parse(userInfo).a_token) {
      aToken = JSON.parse(userInfo).a_token;
    }
  }
  return aToken;
};
const getRefreshToken = () => {
  const userInfo = JSON.parse(localStorage.getItem("uInfo"))
  let rToken = ''
  if (userInfo) {
    if (userInfo.r_token) {
      rToken = userInfo.r_token;
    } else if (JSON.parse(userInfo).r_token) {
      rToken = JSON.parse(userInfo).r_token;
    }
  }
  return rToken;
};

const getSessionOut = () => {
  const sessionString = localStorage.getItem('sessionOut');

  if (sessionString !== null) {
    const session = JSON.parse(sessionString);
    return session;
  }
  return null;
};

const remove = () => {
  localStorage.removeItem("gTerms")
};

const removeUser = () => {
  localStorage.removeItem("uInfo")
}
const removeGuestUser = () => {
  localStorage.removeItem("gInfo")
}
const removeLatLong = () => {
  localStorage.removeItem("sLatLong")
}
const removeNationalWide = () => {
  localStorage.removeItem("sNationalWide")
}
const removeVInfo = () => {
  localStorage.removeItem("sVInfo")
}
const removeCategory = () => {
  localStorage.removeItem("sCategory")
}
const removeFilter = () => {
  localStorage.removeItem("sFilter")
}
const removeSession = () => {
  localStorage.removeItem('sessionOut');
};

export { setUser, getUser, getAuthToken, setGusetUserTerms, getGusetUserTerms, remove, setSocialUserTerms, getSocialUserTerms, getRefreshToken, removeUser, setGusetUser, getGusetUser, removeGuestUser,setLatLong, getLatLong, removeLatLong, setNationalWide, getNationalWide, removeNationalWide, setCategory, getCategory, setVendorInfo, getVendorInfo, removeVInfo, removeCategory, setFilter, getFilter,removeFilter, setSessionOut, getSessionOut, removeSession };
