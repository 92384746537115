/**
 * @file   src\containers\PurchaseHistoryList.tsx
 * @brief  List of Purchased items
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { Container, Col, Row, Button } from 'react-bootstrap';
import PurchaseHistory from '../components/PurchaseHistory';
import { purchaseHistory } from '../store/actions/authAction';
import { ntfnCheck } from '../store/actions/ntfnAction';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../hooks/index';
import * as CONSTANT from '../utils/constants';
import moment from 'moment';
import Strings from '../assets/strings/Strings.json'
import Paginate from '../components/Paginate';

const PurchaseList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [purchaseHistoryInfo, setPurchaseHistoryInfo] = useState<string>('');
  const [purchaseHistoryPage, setPurchaseHistoryPage] = useState<number>(1);
  const purchase_history = (params: any) => {
    dispatch<any>(purchaseHistory(params));
  };
  const purchaseHistoryResponse = useAppSelector((RootReducer) => RootReducer.auth.purchaseHistory);
  const [load, setLoad] = useState<boolean>(true);

  // handle purchase history api call
  useEffect(() => {
    purchase_history({
      p_PageNumber: purchaseHistoryPage,
    });
    dispatch<any>(ntfnCheck());
  }, [purchaseHistoryPage]);

  // handle purchase history api response data
  useEffect(() => {
    if (purchaseHistoryResponse.isLoading === false && purchaseHistoryResponse.isSuccess === true) {
      if (purchaseHistoryResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setPurchaseHistoryInfo(purchaseHistoryResponse.userInfo.user_orders);
      }
      setLoad(false)
    }
  }, [purchaseHistoryResponse]);

  // handle date format
  const dateToMMMDDYYYY = (input: any) => {
    if (!input) return input;
    return moment(input).format('MMM DD, YYYY');
  };

  // handle page change
  const pageChange = (page: any) => {
    if (page) {
      setPurchaseHistoryPage(page);
    }
  };

  // navigate to purchase items details
  const navigateToDetails = (purchase_id: number) => {
    navigate('/return', { state: { purchase_id: purchase_id } });
  };

  // This will go back to the Home Page
  const handleGoBack = () => {
    window.location.href='/'; 
  };

  return (
    <div className="inner-layout">
      {load ?
      <div className='spinner-outer d-flex justify-content-center'>
          <div className=" align-items-center justify-content-center d-flex" >
            <div className="spinner-grow" style={{width: '2rem', height: '2rem',color:'#F9C901',marginRight:10}} role="status" >
            </div>
            <div className="spinner-grow" style={{width: '2rem', height: '2rem',color:'#F9C901',marginRight:10}} role="status">
            </div>
            <div className="spinner-grow" style={{width: '2rem', height: '2rem',color:'#F9C901',marginRight:10}} role="status">
            </div>
            <div className="spinner-grow" style={{width: '2rem', height: '2rem',color:'#F9C901',marginRight:10}} role="status">
            </div>
            <div className="spinner-grow" style={{width: '2rem', height: '2rem',color:'#F9C901'}} role="status">
            </div>
          </div>
      </div>
     :
     <>
      <Container fluid className="page-header">
        <Container>
          <Row>
            <Col md="6" className='d-flex align-items-center'>
              <h2>{Strings.PurchaseHistory.Title}</h2>
            </Col>
            <Col md="6" className="text-md-end btn-wrap">
              <Button onClick={handleGoBack} variant="secondary">
                {Strings.PurchaseHistory.Back}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container>
          {purchaseHistoryInfo.length > 0
            ? purchaseHistoryInfo.map((items: any) => (
              <PurchaseHistory
                image={items.product_image}
                productname={items.product_name}
                productowner={items.business_name}
                price={items.price}
                quantity={items.quantity}
                date={dateToMMMDDYYYY(items.purchase_date)}
                purchase_id={items.purchase_id}
                navigateToDetails={navigateToDetails}
                status={items.refund_status}
              />
            ))
            : <Col className="text-center mt-5">No purchase history available.</Col>}
          <div className='paginate-wrap'>
            {purchaseHistoryResponse.userInfo.total_pages > 1 && (
              <Paginate
                totalRecords={purchaseHistoryResponse.userInfo.total_records}
                currentPage={purchaseHistoryResponse.userInfo.current_page}
                perPage={purchaseHistoryResponse.userInfo.per_page}
                onClick={pageChange}
              />
            )}
          </div>
        </Container>
      </Container>
      </>
      }
    </div>
  );
};

export default PurchaseList;
