/**
 * @file   src\containers\VendorDetails.tsx
 * @brief  Notification page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../assets/styles/vendordetails.scss';
import Strings from '../assets/strings/Strings.json';
import Rating from '../assets/img/rating.svg';
import ActionBtn from '../components/ActionBtn';
import ShareIc from '../assets/img/icons/Share';
import WebIc from '../assets/img/icons/Web';
import LocationIc from '../assets/img/icons/Location';
import Product from '../components/Products';
import Catalogue from '../components/Catalogue';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { vendorProducts } from '../store/actions/vendorAction';
import { ntfnCheck } from '../store/actions/ntfnAction';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as CONSTANT from '../utils/constants';
import { useAppSelector } from '../hooks/index';
import Paginate from '../components/Paginate';
import { getGusetUser, getAuthToken, getCategory, getVendorInfo } from '../utils/localStorage';
import { RWebShare } from 'react-web-share';
import NoImage from '../assets/img/no-image.svg';
import PdfImage from '../assets/img/pdf-image.svg';
import { toast } from 'react-toastify';

interface productInfo {
  prod_price: string;
  product_name: string;
  prod_image: string;
  prod_id: number;
}

const VendorDetails = () => {
  const guestUser = getGusetUser();
  const [productInfo, setProductInfo] = useState<productInfo[]>([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoryId = getCategory();
  const vendorInfo = JSON.parse(getVendorInfo());

  const vendorInfoApi = (payload: any) => dispatch<any>(vendorProducts(payload));
  const vendorPrdtInfoResponse = useAppSelector((RootReducer) => RootReducer.product.vendorProducts);
  const [productPage, setProductPage] = useState<number>(1);
  const [load, setLoad] = useState<boolean>(true);

  // intial loading to top of the page
  useEffect(() => {
    if (!guestUser) {
      dispatch<any>(ntfnCheck());
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, []);

  // call the vendor details api
  useEffect(() => {
    setLoad(true);
    const payload = {
      vendor_id: vendorInfo && vendorInfo.vendorid,
      p_PageNumber: productPage,
      categoryId: categoryId !== ('' || null) ? categoryId : '',
      p_PageSize: 9,
    };
    vendorInfoApi(payload);
  }, [productPage, categoryId]);

  // handle vendor details api repsonse data
  useEffect(() => {
    if (vendorPrdtInfoResponse.isLoading === false && vendorPrdtInfoResponse.isSuccess === true) {
      if (vendorPrdtInfoResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setProductInfo(vendorPrdtInfoResponse.resultInfo.deals_info);
      } else if (vendorPrdtInfoResponse.errorCode === CONSTANT.API_ERRCODE) {
        setProductInfo([]);
      }
      setLoad(false);
    }
  }, [vendorPrdtInfoResponse]);

  // format the number in usa model
  const formattedPhoneNumebr = vendorInfo && vendorInfo.business_phoneno.toString().replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  // handle page change
  const pageChange = (page: any) => {
    if (page) {
      setProductPage(page);
    }
  };

  // handle find us link to open in new window
  const handleFindUsLinkClick = () => {
    if (vendorInfo.findus_link !== null) {
      window.open(vendorInfo.findus_link, '_blank');
    }
  };

  // navigate to corresponding clicked product details page
  const navigateToProductInfo = (product_id: number) => {
    navigate(`/productdetails/${product_id}`, { state: { product_id: product_id, guestUser: guestUser === true ? '' : getAuthToken() } });
  };

  // handle customer review link to open in new window
  const handleCustomerReview = () => {
    window.open(vendorInfo.review_link, '_blank');
  };

  // handle site link to open in new window
  const handleSiteLink = () => {
    window.open(vendorInfo.site_link, '_blank');
  };

  // This will go back to the Home page.
  const handleGoBack = () => {
    window.location.href = '/';
  };

  // handle image to display in vendor profile
  const getProfileImage = (item: any) => {
    let foundObject;
    if (item) {
      foundObject = item.find((obj: { filetype: any }) => obj.filetype !== 3);
      if (foundObject) {
        return foundObject.filepath;
      } else {
        return NoImage;
      }
    } else return NoImage;
  };
  // handle to copy the number to clipboard
  const handleCopyPhoneNo = async () => {
    // Check if there is an extracted number
    if (vendorInfo.business_phoneno) {
      try {
        await navigator.clipboard.writeText(vendorInfo.business_phoneno);
        toast.success('Number copied to clipboard');
      } catch (err) {
        console.error('Failed to copy number to clipboard', err);
      }
    }
  };

  return (
    <div className="inner-layout has-filter">
      {load ? (
        <div className="spinner-outer d-flex justify-content-center">
          <div className=" align-items-center justify-content-center d-flex">
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901' }} role="status"></div>
          </div>
        </div>
      ) : (
        <>
          <Container fluid className="page-header">
            <Container className="d-flex align-items-center justify-content-between header-inner">
              <Col md="6" className="d-flex align-items-center">
                <div className="vendor-title d-flex align-items-center">
                  <div className="vendor-img">
                    <img src={vendorInfo?.vendor_files[0] ? getProfileImage(vendorInfo?.vendor_files) : NoImage} alt="" />
                  </div>
                  <div className="vendor-dtls">
                    <h2>{vendorInfo && vendorInfo?.business_name}</h2>
                    <div className="action-btns d-flex vndr-dtls">
                      {vendorInfo?.review_link && (
                        <p className="m-0">
                          <button onClick={handleCustomerReview} className="customer-review">
                            <img src={Rating} />
                            {Strings.VendorDetails.ReviewHead}
                          </button>
                        </p>
                      )}
                      {vendorInfo?.findus_link && (
                        <RWebShare
                          data={{
                            text: `Description: ${vendorInfo && vendorInfo?.bsc_description}`,
                            url: vendorInfo && vendorInfo?.findus_link,
                            title: vendorInfo && vendorInfo?.business_name,
                          }}
                          onClick={() => console.log('shared successfully!')}
                        >
                          <span>
                            <ActionBtn tooltip="Share">
                              <ShareIc />
                            </ActionBtn>
                          </span>
                        </RWebShare>
                      )}
                      {vendorInfo?.site_link && (
                        <ActionBtn tooltip="Website" onClick={handleSiteLink} link={vendorInfo && vendorInfo.site_link}>
                          <WebIc />
                        </ActionBtn>
                      )}
                      {/* <ActionBtn tooltip="Location">
              <LocationIc />
            </ActionBtn> */}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6" className="text-md-end btn-wrap">
                <Button onClick={handleGoBack} variant="secondary">
                  {Strings.Profile.Back}
                </Button>
              </Col>
            </Container>
          </Container>
          <Container fluid>
            <Container className="content-area">
              <Row>
                <Col lg="8" className="product-outer">
                  <h3>{Strings.VendorDetails.Subhead1}</h3>
                  <Row>
                    {productInfo.length > 0 ? (
                      productInfo.slice(0, 3).map((product) => (
                        <Col lg="4">
                          <Product
                            title={product.prod_name}
                            price={product.prod_price}
                            image={product.prod_image}
                            onClick={() => {
                              navigateToProductInfo(product.prod_id);
                            }}
                          />
                        </Col>
                      ))
                    ) : (
                      <Col className="text-center mt-5">{productInfo.length === 0 ? Strings.VendorDetails.NoProducts : ''}</Col>
                    )}
                  </Row>
                  {productInfo.length > 3 ? <h3 className="mt-2">{Strings.VendorDetails.Subhead2}</h3> : <></>}
                  <Row>
                    {productInfo.length === 0 ? (
                      ''
                    ) : productInfo.length > 3 ? (
                      productInfo.slice(3).map((product) => (
                        <Col lg="4">
                          <Product
                            title={product.prod_name}
                            price={product.prod_price}
                            image={product.prod_image}
                            onClick={() => {
                              navigateToProductInfo(product.prod_id);
                            }}
                          />
                        </Col>
                      ))
                    ) : (
                      <Col className="text-center mt-5">{productInfo.length === 0 ? Strings.VendorDetails.NoProducts : ''}</Col>
                    )}
                  </Row>
                </Col>
                <Col lg="4">
                  <div className="contact-vendor">
                    <h3 className="pt-0">{vendorInfo && vendorInfo?.business_name}</h3>
                    {(vendorInfo?.u_address1 || vendorInfo?.p_address || vendorInfo?.proxy_zip_code) && (
                      <div className="address-vendor">
                        <p className="title">{Strings.VendorDetails.Address}</p>
                        <p className="address-txt">
                          {vendorInfo && vendorInfo.broadcasttype === 1 ? (
                            <>
                              {vendorInfo.u_address1 ? `${vendorInfo.u_address1.trim()}, ` : ''}
                              {vendorInfo.u_city ? `${vendorInfo.u_city.trim()}, ` : ''}
                              {vendorInfo.p_country ? `${vendorInfo.p_country.trim()}, ` : ''}
                              {vendorInfo.u_state ? `${vendorInfo.u_state.trim()} ` : ''}
                              {vendorInfo.u_zip ? `${vendorInfo.u_zip.trim()}` : ''}
                            </>
                          ) : vendorInfo && vendorInfo.broadcasttype === 2 ? (
                            <>
                              {vendorInfo.p_address ? `${vendorInfo.p_address.trim()}, ` : ''}
                              {vendorInfo.p_city ? `${vendorInfo.p_city.trim()}, ` : ''}
                              {vendorInfo.p_country ? `${vendorInfo.p_country.trim()}, ` : ''}
                              {vendorInfo.p_state ? `${vendorInfo.p_state.trim()} ` : ''}
                              {vendorInfo.p_zip ? `${vendorInfo.p_zip.trim()}` : ''}
                            </>
                          ) : vendorInfo && vendorInfo.broadcasttype === 3 ? (
                            <>{vendorInfo.proxy_zip_code ? `${vendorInfo.proxy_zip_code.trim()}, ` : ''}</>
                          ) : (
                            'Address not available'
                          )}
                        </p>
                      </div>
                    )}
                    <div className="other-dtls-vendor">
                      {vendorInfo && vendorInfo.business_phoneno && (
                        <span onClick={handleCopyPhoneNo} className="phone d-inline-block">
                          {formattedPhoneNumebr}
                        </span>
                      )}
                      {vendorInfo?.findus_link && (
                        <span className="website d-inline-block" onClick={handleFindUsLinkClick}>
                          Find Us on
                        </span>
                      )}
                    </div>
                    <div className="catecatalogue-wrap">
                      <Row>
                        {vendorInfo && vendorInfo.vendor_files && vendorInfo.vendor_files.length !== 0 &&
                          vendorInfo.vendor_files.map((image: any, index: any) => (
                            <Col md="4">
                              <Catalogue image={image.filepath} filetype={image.filetype} />
                            </Col>
                          ))}
                      </Row>
                    </div>
                    <h3>Welcome To "{vendorInfo && vendorInfo.business_name} !"</h3>
                    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={160}>
                      <p className="common-content">{vendorInfo && vendorInfo.bsc_description}</p>
                    </Scrollbars>
                    {vendorInfo?.about && (
                      <>
                        <h3 className="mt-4">{Strings.VendorDetails.About}</h3>
                        <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={160}>
                          <p className="common-content">{vendorInfo && vendorInfo.about}</p>
                        </Scrollbars>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
          <div className="paginate-wrap">
            {vendorPrdtInfoResponse.resultInfo.total_pages > 1 && (
              <Paginate
                totalRecords={vendorPrdtInfoResponse.resultInfo.total_records}
                currentPage={vendorPrdtInfoResponse.resultInfo.current_page}
                perPage={vendorPrdtInfoResponse.resultInfo.per_page}
                onClick={pageChange}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default VendorDetails;
