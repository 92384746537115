/**
 * @file   src\components\Footer.tsx
 * @brief  Footer component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import './Footer.scss';
import Strings from '../assets/strings/Strings.json';

const Footer = () => {
  return (
    <footer className='d-flex justify-content-center align-items-center'>
      {Strings.Footer.Copyright}
    </footer>
  );
};

export default Footer;
