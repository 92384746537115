/**
 *@file SearchMain.tsx
 *@brief Reusable search component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import { useState,useRef,useEffect } from 'react';
import "./SearchMain.scss";
import { Button, DropdownButton, Col, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import Filter from "../assets/img/icons/Filter";
import Search from "../assets/img/icons/Search";
import NBInputGroup from '../components/NBInputGroup';
import IcProducts from '../assets/img/icons/Products';
import CurrentLocation from '../assets/img/icons/CurrentLocation';

const HomeSearch = (props: any) => {

    const catagory = props.categoryList
    const subCatagory = props.subCategoryList
    const [value, setValue] = useState(props.subCatagoryValue ? props.subCatagoryValue : []);
    const divRef = useRef<any>(null);
    const inputRef = useRef<any>(null);

    // handle close of current location style when clicking outside of the style
    useEffect(() => {
        const handleClickOutside = (event: any) => {
      if (divRef.current && inputRef.current && !divRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
            props.setZipcodeSelect(false);
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);
    
      // handle value change
    const handleChange = (val:any) => {
        const index = value.indexOf(val);
        if (index !== -1) {
            const newArray = [...value];
            newArray.splice(index, 1);
            setValue(newArray);
        } else {
            setValue([...value, val]);
        }
    }

    // handle automatic close of filter when clicking outside of the style.
    const changeDropdown = (isOpen: any) => {       
        if(isOpen){
            props.setShowDropdown(true)
        } else {
            props.setShowDropdown(false)
        }        
      }
      
    return (
        <>
            <div className='search-wrap d-flex'>
                <input
                    value={props.value}
                    onChange={props.addressOnChange}
                    onFocus={props.onFocus}
                    className='search-box'
                    type='search'
                    placeholder="Search by vendor"
                />
                <div className='zip-wrap position-relative'>
                    <input
                        className='zip-code'
                        placeholder="Enter your Zip Code"
                        type='text'
                        onClick={props.zipcodeSelectChange}
                        onChange={props.onChangeZipcode}
                        value={props.city === '' ? props.zipCode_value : props.city}
                    maxLength={5}
                        ref={inputRef}
                        onKeyDown={props.searchOnEnterKey}
                    />

                    {props.zipcodeSelect && <div className='position-absolute current-location d-flex align-items-center' onClick={props.currentLocation} ref={divRef}><CurrentLocation />Use My Current Location</div>}
                </div>
                <DropdownButton id="dropdown-basic-button" variant="secondary" align="end" autoClose="outside" className="filter" onToggle={changeDropdown} title={<Filter  />} show={props.showDropdown}>
                    <h3>Filter By</h3>
                    <h6>Price Range</h6>
                    <Row>
                        <Col md='6' className='price-range'>
                            <NBInputGroup
                                id="Min"
                                name="Min"
                                type="text"
                                label="Min"
                                maxLength={9}
                                onChange={props.priceChange}
                                value={props.minValue}
                                onBlur={props.changeMinDecimal}
                            />
                        </Col>
                        <Col md='6' className='price-range'>
                            <NBInputGroup
                                id="Min"
                                name="Max"
                                type="text"
                                label="Max"
                                maxLength={9}
                                onChange={props.priceChange}
                                value={props.maxValue}
                                error={props.maxValueError}
                                onBlur={props.changeMaxDecimal}
                            />
                        </Col>
                    </Row>

                    {catagory && catagory.map((data1: any, key: number) => (
                        <div className='filter-by' key={key}>
                            <h4><IcProducts />{data1.category_name}</h4>
                            {subCatagory && subCatagory.map((data2: any, subKey: number) => (
                                data2.category_id === data1.category_id && (
                                    <ToggleButtonGroup key={subKey} type="checkbox" value={value} onChange={() => { props.onChange(data2.subcategory_id); handleChange(subKey); }}>
                                        <ToggleButton variant='light' id={`tbg-btn-${subKey}`} value={subKey}>
                                            {data2.subcategory_name}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                )
                            ))}
                        </div>
                    ))}
                    <div className='button-nav'>
                        <Button onClick={() => { props.clearFilter(); setValue([]); }} variant='success'>Reset</Button>
                        <Button variant='primary' onClick={props.applyFliter} disabled={props.applyBtnDisable}>Apply</Button>
                    </div>

                </DropdownButton>
                <Button onClick={props.onClick} className="search"><Search /></Button>
            </div>

        </>

    );
};
export default HomeSearch;
