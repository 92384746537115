/**
 *@file PurchaseHistory.tsx
 *@brief Purchase History component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PurchaseHistory } from '../interfaces/GeneralInterface';
import Strings from '../assets/strings/Strings.json';
import './Purchase.scss';
import NoImage from '../assets/img/no-image.svg'
const PurchaseHistoryMain = React.forwardRef((props: PurchaseHistory, ref: any) => {
  const [statusData, setStatusData] = useState<any>(null);
  // handle status of the refund
  useEffect(() => {
    switch(props.status){
      case 2: setStatusData({text:"Refund Approved", color:"#00A706" });break;
      case 1: setStatusData({text:"Refund Requested", color:"#F78C00" }); break;
      default:setStatusData({text:"", color:"" }); break;
    }
  }, [props])
  
  return (
    <div className='purchase-history-main' style={{ cursor: (props.status !==1) && (props.status !==2) && ( props.price && props.price !== 0)  ? 'pointer' : 'auto' }} onClick={() => {(props.status !==1) && (props.status !==2) && (props.price && props.price !==0) && props.navigateToDetails(props.purchase_id)}}>
      <Row>
        <Col xl={4} lg={12} className='d-flex align-items-center' >
          <div className='purchase-img'>
            <img src={props.image? props.image : NoImage} alt='' />
          </div>
          <div className='product-name'>
            <h5>{props.productname}</h5>
            <p>{props.productowner}</p>
          </div>
        </Col>
        <Col xl={4} lg={6} md={8} className='d-flex justify-content-between align-items-center product-details'>
          <div>
            <span>{Strings.Purchase.Price}</span>
            <b>$ {parseFloat(props.price).toFixed(2)}</b>
          </div>
          <div>
            <span>{Strings.Purchase.Quantity}</span>
            <b>{props.quantity}</b>
          </div>
          <div>
            <span>{Strings.Purchase.Date}</span>
            <b>{props.date}</b>
          </div>
        </Col>
        <Col xl={4} lg={6} md={4} className='d-flex align-items-center justify-content-end' style={{color: statusData?.color}}>{statusData?.text}</Col>
      </Row>

    </div>
  );
});

export default PurchaseHistoryMain;
