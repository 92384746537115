import * as alerts from '../utils/alerts';

export const validateLogin = (loginData: { email: string; password: any }) => {
  const errors = { email: '', password: '' };
  const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (!loginData.email) {
    errors.email = 'Enter Email Address.';
  } else if (!reg.test(loginData.email)) {
    errors.email = 'Enter a valid Email address.';
  }
  if (!loginData.password) {
    errors.password = 'Enter Password.';
  }
  return errors;
};

export const emailValidation = (email: any) => {
  let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  let result = pattern.test(email)
  return result;
};

export const passwordValidation = (value: any, fieldLabel: string) => {
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;
  const noSpaceRegExp = /^\S*$/;

  const passwordLength = value.length;
  const uppercasePassword = uppercaseRegExp.test(value);
  const lowercasePassword = lowercaseRegExp.test(value);
  const specialCharPassword = specialCharRegExp.test(value);
  const minLengthPassword = minLengthRegExp.test(value);
  const space = noSpaceRegExp.test(value);

  let errMsg = "";
  if (passwordLength === 0) {
    errMsg = fieldLabel == "" ? "Enter Password." : "Enter " + fieldLabel + '.';
  } else if (!minLengthPassword) {
    errMsg = alerts.ENTER_VALID_PASSWORD;
  } else if (!uppercasePassword) {
    errMsg = alerts.ENTER_VALID_PASSWORD;
  } else if (!lowercasePassword) {
    errMsg = alerts.ENTER_VALID_PASSWORD;
  } else if (!specialCharPassword) {
    errMsg = alerts.ENTER_VALID_PASSWORD;
  } else if (!space) {
    errMsg = alerts.ENTER_VALID_PASSWORD;
  }
  return errMsg;
}

export const validatePhoneNo = (value: string) => {
  const lengthRegExp = /^\D*(?:\d\D*){10,10}$/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const validNumber = /(\d)\1{4}/
  const consecutiveNumber = /(.)(?:(?:0(?=1|\b)|1(?=2|\b)|2(?=3|\b)|3(?=4|\b)|4(?=5|\b)|5(?=6|\b)|6(?=7|\b)|7(?=8|\b)|8(?=9|\b)|9(?=0|\b)){4,})/

  const lengthOfPhoneNumber = lengthRegExp.test(value);
  const specialCharCheck = specialCharRegExp.test(value);
  const isNumberValid = validNumber.test(value);
  const validateConsecutiveNumber = consecutiveNumber.test(value);

  let errMsg = ""
  if (!lengthOfPhoneNumber) {
    errMsg = alerts.ENTER_VALID_PHONE_NUMBER;
  } else if (specialCharCheck) {
    errMsg = alerts.ENTER_VALID_PHONE_NUMBER;
  } else if (isNumberValid) {
    errMsg = alerts.ENTER_VALID_PHONE_NUMBER;
  } else if (validateConsecutiveNumber) {
    errMsg = alerts.ENTER_VALID_PHONE_NUMBER;
  }
  return errMsg;
}

export const nameValidation = (value: string) => {
  const specialCharRegExp = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/;
  const alphanumericRegx = /[0-9]+/g;
  const alphanumericTest = alphanumericRegx.test(value);
  const specialCharCheck = specialCharRegExp.test(value);
  let errMsg = "";
  if (specialCharCheck) {
    errMsg = alerts.SPECIALCHARACTER_ERROR;
  } else if (alphanumericTest) {
    errMsg = alerts.NUMBER_ERROR;
  }
  return errMsg;
}

export const isOTPValid = (otp: any) => {
  // Remove any leading or trailing whitespace
  otp = otp.trim();
  // Check if the OTP is exactly 4 digits long and consists only of numeric characters
  if (/^\d{4}$/.test(otp)) {
    return true; // OTP is valid
  } else {
    return false; // OTP is not valid
  }
}

export const validateImageTypes = (file: any) => {
  const validExtensions = ['png', 'jpeg', 'jpg', 'PNG', 'JPG', 'JPEG'];
  const fileExtension = file.type.split('/')[1];
  const typeIncluded = validExtensions.includes(fileExtension);
  let errMsg = '';
  if (!typeIncluded) {
    errMsg = 'Only .JPEG and .PNG files are supported.';
  } else if (file.size > 6000000) {
    errMsg = 'Upload an image within 6 MB size.';
  }
  return errMsg;
};

export const youtubePatterns = (url: any) => {
  const youtubePatterns = [
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
    /^https?:\/\/(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})$/,
  ];
  // Check if the URL matches any of the YouTube patterns
  return youtubePatterns.some(pattern => pattern.test(url));
};


export const getIdfromYoutubeLink = (url:any) => {
  const patterns = [
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
    /^https?:\/\/(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})$/,
  ];
  for (const pattern of patterns) {
    const match = url.match(pattern);
    if (match) {
      return match[1]; 
    }
  }
  return null;
}
