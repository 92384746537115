/**
 * @file   src\containers\Payment.tsx
 * @brief  Payment page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from 'react';
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';
import '../assets/styles/payment.scss';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { productLock, createCustomer, productMakeOrder } from '../store/actions/productAction';
import { ntfnCheck } from '../store/actions/ntfnAction';
import { resetLockProductState, resetCustomerCreateState, resetMakeOrderProductState } from '../store/slices/productSlice';
import * as CONSTANT from '../utils/constants';
import Strings from '../assets/strings/Strings.json'
import NBInputGroup from '../components/NBInputGroup';
import { getUser, getGusetUser, getAuthToken } from '../utils/localStorage';
import * as alerts from '../utils/alerts'
import PaymentSuccess from '../assets/img/paymentSuccess.svg';
import { IPaymentAddressData, IPaymentAddressDataError } from '../interfaces/GeneralInterface';
const Payment = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const guestUser = getGusetUser();
  const productInfo = location.state?.product_info;
  const quantity = location.state?.quantity;
  const total = productInfo && parseFloat(productInfo?.prod_price.replace(/,/g, '')) * quantity;
  const customer = location.state?.customer_created;
  const user_details = JSON.parse(getUser());
  const productLockRsponse = useAppSelector((RootReducer) => RootReducer.product.lockProduct);
  const createCustomerRsponse = useAppSelector((RootReducer) => RootReducer.product.createCustomer);
  const productMakeOrderRsponse = useAppSelector((RootReducer) => RootReducer.product.makeOrderProduct);
  const refAddress = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const [loadMap, setLoadMap] = React.useState(false);
  const [place, setPlace] = React.useState(null);
  const [customerData, setCustomerData] = React.useState<IPaymentAddressData>({
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state: '',
    country: '',
  });

  const [customerDataError, setCustomerDataError] = React.useState<IPaymentAddressDataError>({
    address1: '',
    city: '',
    zip: '',
    state: '',
  });
  const [load, setLoad] = React.useState<boolean>(false);
  const [showPayment, setShowPayment] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (productLockRsponse.isLoading === false && productLockRsponse.isSuccess === true) {
      if (productLockRsponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        if (productLockRsponse.resultInfo.client_info.client_secret) {
          navigate('/paymentcard', {
            state: {
              client_secret: productLockRsponse.resultInfo.client_info.client_secret,
              billingDetails: productLockRsponse.resultInfo.client_info.billingDetails,
              customerInfo: productLockRsponse.resultInfo.client_info.customerInfo,
              total: total,
              productInfo: productInfo,
              quantity: quantity,
            },
          });
        } else if (productLockRsponse.resultInfo.client_info.client_secret === null){
          const payload = {
            prod_id: productInfo?.prod_id,
            qty: quantity,
            amount: total,
            paymentIntentId: '',
            isZeroPayment: total === 0 ? 1 : 0,
          };
          dispatch(productMakeOrder(payload));
        }
        handleClose();
      } else {
        toast.error(productLockRsponse.errorMessage);
      }
      setLoad(false);
      dispatch(resetLockProductState());
    }
    else if (productLockRsponse.isLoading === false && productLockRsponse.isSuccess === false) {
      setLoad(false);
      dispatch(resetLockProductState());
    }
  }, [productLockRsponse]);

  // handles create customer api response
  React.useEffect(() => {
    if (createCustomerRsponse.isLoading === false && createCustomerRsponse.isSuccess === true) {
      if (createCustomerRsponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        const payloads = {
          prod_id: productInfo?.prod_id,
          qty: quantity,
          amount: total,
          isZeroPayment: total === 0 ? 1 : 0,
        };
        dispatch(productLock(payloads));
      } else {
        toast.error(createCustomerRsponse.errorMessage);
      }
      dispatch(resetCustomerCreateState());
    }
  }, [createCustomerRsponse]);

  // handles product make order api response
  React.useEffect(() => {
    if (productMakeOrderRsponse.isLoading === false && productMakeOrderRsponse.isSuccess === true) {
      if (productMakeOrderRsponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setShowPayment(true);
      } else {
        toast.error(productMakeOrderRsponse?.errorMessage)
        setLoad(false);
      }
    }
    dispatch(resetMakeOrderProductState());
  }, [productMakeOrderRsponse]);

  // handles google map loading
  React.useEffect(() => {
    if (!loadMap && show) {
      loadGoogleMapScript(() => {
        setLoadMap(true);
      });
    }
  }, [loadMap, show]);

  // handles google map
  React.useEffect(() => {
    if (loadMap && show) {
      initPlaceAPI();
    }
  }, [loadMap, show]);

  // handles notification call api
  React.useEffect(() => {
    dispatch<any>(ntfnCheck());
  }, []);

  // handles customer billing address input data
  React.useEffect(() => {
    if (place) {
      const address = place;
      let streetNumber = '';
      let routeInfo = '';
      let plusCode = '';
      let neighborhood = '';
      let premise = '';
      let businessCity = '';
      address.forEach((item: any) => {
        if (item.types.indexOf('street_number') >= 0) {
          streetNumber = item.long_name + " ";
          setCustomerData((customerData: any) => ({
            ...customerData,
            address1: streetNumber,
          }));
        }

        if (item.types.indexOf("route") >= 0) {
          routeInfo = streetNumber + item.long_name + " ";
          setCustomerData((customerData: any) => ({
            ...customerData,
            address1: routeInfo,
          }));
        }
        if (item.types.indexOf("plus_code") >= 0) {
          if (streetNumber === "") {
            plusCode = streetNumber + routeInfo + item.long_name + " ";
          } else {
            plusCode = routeInfo + item.long_name + " ";
          }
          setCustomerData((customerData: any) => ({
            ...customerData,
            address1: plusCode,
          }));
        }

        if (item.types.indexOf("neighborhood") >= 0) {
          if (streetNumber === "") {
            neighborhood = streetNumber + routeInfo + plusCode + item.long_name + " ";
          } else {
            neighborhood = routeInfo + plusCode + item.long_name + " ";
          }
          setCustomerData((customerData: any) => ({
            ...customerData,
            address1: neighborhood,
          }));
        }
        if (item.types.indexOf("premise") >= 0) {
          if (streetNumber === "") {
            premise =
              streetNumber +
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          } else {
            premise =
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          }
          setCustomerData((customerData: any) => ({
            ...customerData,
            address1: premise,
          }));
        }
        if (item.types.indexOf("administrative_area_level_1") >= 0) {
          setCustomerData((customerData: any) => ({
            ...customerData,
            state: item.short_name,
          }));
        }
        if (item.types.indexOf('locality') >= 0) {
          businessCity = item.short_name;
          setCustomerData((customerData: any) => ({
            ...customerData,
            city: item.short_name,
          }));
        } else if (item.types.indexOf('administrative_area_level_3') >= 0 && businessCity === '') {
          setCustomerData((customerData: any) => ({
            ...customerData,
            city: item.short_name,
          }));
        }

        if (item.types.indexOf("postal_code") >= 0) {
          setCustomerData((customerData: any) => ({
            ...customerData,
            zip: item.long_name,
          }));
        }
        if (item.types.indexOf('country') >= 0) {
          setCustomerData((customerData: any) => ({
            ...customerData,
            country: item.short_name,
          }));
        }
      });
    }
  }, [place]);

  // handles product checkout
  const handleProductCheckout = () => {
    if (customer === 0) {
      handleShow();
    } else if (productInfo) {
      const payload = {
        prod_id: productInfo?.prod_id,
        qty: quantity,
        amount: total,
        isZeroPayment: total === 0 ? 1 : 0,
      };
      dispatch(productLock(payload));
      setLoad(true)
    }
  };

  // handles customer billing address popup
  const handleClose = () => {
    setShow(false);
    setCustomerData((customerData: any) => ({
      ...customerData,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    }));
  };

  // handle success modal
  const handleShow = () => {
    setShow(true);
    setLoad(false);
  };

  // handles customer data changes
  const handleCustomerChange = (event: any) => {
    setCustomerData((customerData: any) => ({
      ...customerData,
      [event.target.name]: event.target.value,
    }));
  };

  // handles customer address validations
  const validate = () => {
    let isValid = true;
    setCustomerDataError((customerDataError: any) => ({ ...customerDataError, address1: '', address2: '', city: '', state: '', zip: '' }));
    if (customerData.address1.trim() === '') {
      setCustomerDataError((customerDataError: any) => ({ ...customerDataError, address1: alerts.ENTER_ADDRESS }));
      isValid = false;
    }

    return isValid;
  }

  // load google map script
  const loadGoogleMapScript = (callback: any) => {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      callback();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}&callback=Function.prototype&libraries=places`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", callback);
      setLoadMap(true);
    }
  };

  // load google map script
  const initPlaceAPI = () => {
    setTimeout(() => {
      if (window.google) {
        let autocomplete = new window.google.maps.places.Autocomplete(
          refAddress.current,
          {
            fields: ["address_components", "geometry"],
            types: ["address"],
          },
        );
        new window.google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            let place = autocomplete.getPlace();
            setCustomerData((customerData: any) => ({
              ...customerData,
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip: '',
              country: ''
            }));
            setPlace(place.address_components);
          }
        );
      }
    }, 500);
  };

  // handles customer create data
  const handleCustomerCreate = () => {
    const validation = validate()
    if (validation) {
      const payload = {
        email: user_details?.email,
        full_name: user_details?.f_name + user_details?.l_name,
        addressLine1: customerData?.address1,
        addressLine2: customerData?.address2,
        phone: user_details?.mob_number,
        postal_code: customerData?.zip,
        city: customerData?.city,
        state: customerData?.state,
        country: customerData?.country,
        prod_id: productInfo?.prod_id
      }
      dispatch(createCustomer(payload));
    }
  }
  // This will go back to the previous page in the browser history
  const handleGoBack = () => {
    navigate(`/productdetails/${productInfo?.prod_id}`, { state: { product_id: productInfo?.prod_id, guestUser: guestUser === true ? '' : getAuthToken() } });
  };

  // handle payment success modal close
  const handleClosePaymentModal = () => {
    setShowPayment(false);
    navigate('/');
  };

  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container>
          <Row>
            <Col md="6" className="d-flex align-items-center">
              <h2>{Strings.Payment.Title}</h2>
            </Col>
            <Col md="6" className="text-md-end btn-wrap">
              <Button onClick={handleGoBack} variant="secondary">
                {Strings.Payment.Back}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container className="payment">
          <Row>
            <Col lg="12">
              <Row>
                <Col lg="3" className="item-image">
                  <img src={productInfo?.thumbnail_image} alt="" />
                </Col>
                <Col lg="6" className="item-des">
                  <h2>{Strings.Payment.Description}</h2>
                  <p>{productInfo?.prod_description}</p>
                  <span className="price d-inline-block">${productInfo?.prod_price}</span>
                </Col>
                <Col lg="3" className="item-price">
                  <div className="price-dtl-wrap">
                    <div className="price-item d-flex justify-content-between">
                      <span>Quantity</span>
                      <span>{quantity}</span>
                    </div>
                    <div className="price-item d-flex justify-content-between">
                      <span>Total</span>
                      <span>${total.toFixed(2)}</span>
                    </div>
                    <Button disabled={load} onClick={handleProductCheckout} variant="primary" className="w-100">
                      {load ? <Spinner animation="border" size="sm" variant="light" /> :
                        <>{Strings.Payment.CheckOut}</>
                      }
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
          <Modal.Body>
            <Col>
              <h1 style={{ paddingBottom: 25 }}>Billing Address</h1>
              <Col xl={12} >
                <NBInputGroup
                  id="address1"
                  name="address1"
                  type="text"
                  label="Address 1"
                  onChange={handleCustomerChange}
                  value={customerData.address1}
                  error={customerDataError.address1}
                  ref={refAddress}
                />
              </Col>
              <Col xl={12}>
                <NBInputGroup id="address2" name="address2" type="text" label="Address 2" onChange={handleCustomerChange} value={customerData.address2} />
              </Col>
              <Row>
                <Col xl="7" lg="6" md="6">
                  <NBInputGroup id="city" name="city" type="text" label="City" value={customerData.city} error={customerDataError.city} />
                </Col>
                <Col>
                  <NBInputGroup id="zip" name="zip" type="text" label="ZIP code" value={customerData.zip} error={customerDataError.zip} />
                </Col>
              </Row>
              <Col xl={12}>
                <NBInputGroup id="state" name="state" type="text" label="State" value={customerData.state} error={customerDataError.state} />
              </Col>
              <div className='button-wrap'>
                <Button onClick={handleClose} style={{ marginTop: 10, marginRight: 15 }} variant="secondary">
                  Cancel
                </Button>
                <Button onClick={handleCustomerCreate} style={{ marginTop: 10 }} variant="primary">
                  Continue
                </Button>
              </div>

            </Col>
          </Modal.Body>
        </Modal>
        <Modal show={showPayment} onHide={handleClosePaymentModal} centered size="sm">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="pt-3 pb-5 text-center">
          <img src={PaymentSuccess} />
          <h4>Success!</h4>
        </Modal.Body>
      </Modal>
      </Container>
    </div>
  );
};

export default Payment;
