/**
 * @file   src\containers\NotificationList.tsx
 * @brief  Container for product details page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useState } from 'react';
import { Button, Carousel, Col, Container, Row, ToggleButton, ToggleButtonGroup, Modal } from 'react-bootstrap';
import { NavLink, useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from 'moment';
import { RWebShare } from 'react-web-share';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { resetProductDetailState, resetreportProductState, resetProductMessageSendState } from '../store/slices/productSlice';
import { productDetails, reportOptions, reportProduct, productMessages, productMessageHistory, productMessageSend } from '../store/actions/productAction';
import '../assets/styles/vendordetails.scss';
import Strings from '../assets/strings/Strings.json';
import ActionBtn from '../components/ActionBtn';
import ShareIc from '../assets/img/icons/Share';
import FlagIc from '../assets/img/icons/Flag';
import Category from '../components/Category';
import NBInputQty from '../components/NBInputQty';
import ServiceItem from '../components/ServiceTypes';
import AppsAdRight from '../components/AppsAdRight';
import * as CONSTANT from '../utils/constants';
import NBInputGroup from '../components/NBInputGroup';
import NBSelect from '../components/NBSelect';
import { getAuthToken, getGusetUser } from '../utils/localStorage';
import { categoryIds } from '../utils/enums';
import ChatIcon from '../assets/img/icons/Chat';
import Chat from '../components/Chat';
import Chatbtn from '../assets/img/chat-btn.svg';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import YouTube from 'react-youtube';
import { youtubePatterns, getIdfromYoutubeLink } from '../utils/authHelper';
import { ntfnCheck } from '../store/actions/ntfnAction';
import { error_code } from '../utils/enums';

const ProductDetail = () => {
  const dispatch: ThunkDispatch<any, undefined, AnyAction> = useAppDispatch();
  const navigate = useNavigate();
  const guestUser = getGusetUser();

  const { id } = useParams();
  const productDetailResponse = useAppSelector((RootReducer) => RootReducer.product.productDetails);
  const reportProductResponse = useAppSelector((RootReducer) => RootReducer.product.reportproduct);
  const reportReasonsResponse = useAppSelector((RootReducer) => RootReducer.product.reportReasons);
  const questionListResponse = useAppSelector((RootReducer) => RootReducer.product.questionList);
  const productMessageResponse = useAppSelector((RootReducer) => RootReducer.product.productMessage);
  const productMessageSendResponse = useAppSelector((RootReducer) => RootReducer.product.productMessageSend);
  const [vendorInfo, setvendorInfo] = useState<any>('');
  const [productImage, setProductImages] = useState<any>([]);
  const [productInfo, setProductInfo] = useState<any>('');
  const [vendorFiles, setVendorFiles] = useState<any>([]);
  const [count, setCount] = useState<number>(1);
  const [customer, setCustomer] = useState<string>('');
  const [reportReasons, setReportReasons] = useState<any>([]);
  const [questionList, setQuestionList] = useState<any>([]);
  const [productMessagesHistory, setProductMessagesHistory] = useState<any>([]);
  const [questionAsked, setQuestionAsked] = useState<any>([]);
  const [selectedMsg, setSelectedMsg] = useState<any>('');
  const [reportText, setReportText] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [viewChat, setViewChat] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [gusetUserSignOutShow, setGusetUserSignOutShow] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(true);
  const handleClose = () => {
    setShow(false);
    setReportText('');
  };
  const handleShow = () => {
    setShow(true);
  };
  const location = useLocation();

  // handle function to call products api and report options api call
  useEffect(() => {
    const payload = {
      prod_id: id,
      access_token: (getAuthToken() !== '' && guestUser === true) || null ? '' : getAuthToken(),
    };
    dispatch(productDetails(payload));
    if (!guestUser) {
      dispatch(ntfnCheck());
      dispatch(reportOptions());
      dispatch(productMessages());
      dispatch(
        productMessageHistory({
          prod_id: id,
        }),
      );
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, []);

  // handles product details api response
  useEffect(() => {
    if (productDetailResponse.isLoading === false && productDetailResponse.isSuccess === true) {
      setLoad(false);
      if (productDetailResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        if (productDetailResponse.userInfo?.vendor_info && productDetailResponse.userInfo?.vendor_info[0]) {
          setvendorInfo(productDetailResponse.userInfo?.vendor_info[0]);
        }
        if (productDetailResponse.userInfo?.product_info && productDetailResponse.userInfo?.product_info[0]) {
          setProductInfo(productDetailResponse.userInfo?.product_info[0]);
        }
        if (productDetailResponse.userInfo?.prod_images) {
          let images = productDetailResponse.userInfo?.prod_images;
          if (productDetailResponse.userInfo?.product_info[0].video_link) {
            let newImgObj = {
              image_path: productDetailResponse.userInfo?.product_info[0].video_link,
              prod_id: productDetailResponse.userInfo?.product_info[0].prod_id,
            };
            images = [...images, newImgObj];
          }
          setProductImages(images);
          // setProductImages(productDetailResponse.userInfo?.prod_images);
        }
        if (productDetailResponse.userInfo?.vendor_files) {
          setVendorFiles(productDetailResponse.userInfo?.vendor_files);
        }
        if (productDetailResponse.userInfo?.customerInfo) {
          setCustomer(productDetailResponse.userInfo.customerInfo?.customerCreated);
        }
      } else {
        toast.error(productDetailResponse?.errorMessage);
      }
      dispatch(resetProductDetailState());
    }
  }, [productDetailResponse]);

  // handles report product reasons api response
  useEffect(() => {
    if (reportReasonsResponse.isLoading === false && reportReasonsResponse.isSuccess === true) {
      if (reportReasonsResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setReportReasons(reportReasonsResponse.resultInfo?.reasons_info);
        setSelectedValue(1);
      } else {
        toast.error(reportReasonsResponse?.errorMessage);
      }
    }
  }, [reportReasonsResponse]);

  // handles report product response
  useEffect(() => {
    if (reportProductResponse.isLoading === false && reportProductResponse.isSuccess === true) {
      if (reportProductResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        toast.success(reportProductResponse?.errorMessage);
        handleClose();
        setSelectedValue(1);
        setReportText('');
      } else {
        toast.error(reportProductResponse?.errorMessage);
      }
      dispatch(resetreportProductState());
    }
  }, [reportProductResponse]);

  useEffect(() => {
    if (questionListResponse.isLoading === false && questionListResponse.isSuccess === true) {
      if (questionListResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setQuestionList(questionListResponse.resultInfo?.questions_info);
      }
    }
  }, [questionListResponse]);

  useEffect(() => {
    if (productMessageSendResponse.isLoading === false && productMessageSendResponse.isSuccess === true) {
      if (productMessageSendResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        dispatch(productMessages());
        dispatch(
          productMessageHistory({
            prod_id: id,
          }),
        );
        setQuestionAsked([...questionAsked, selectedMsg]);
        setSelectedMsg('');
      }
      dispatch(resetProductMessageSendState());
    }
  }, [productMessageSendResponse]);

  useEffect(() => {
    if (productMessageResponse.isLoading === false && productMessageResponse.isSuccess === true) {
      if (productMessageResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setProductMessagesHistory(productMessageResponse.resultInfo?.messages_info);
      }
    }
  }, [productMessageResponse]);

  //validates phone number format
  const phoneFormat = (value: any) => {
    if (value) {
      let formattedPhoneNumber = value;
      if (value.length <= 3) {
        formattedPhoneNumber = value;
      } else if (value.length > 3 && value.length <= 6) {
        formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
      } else if (value.length > 6) {
        formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
      }
      return formattedPhoneNumber;
    }
  };

  // handles increment and decrement count
  const countIncrement = () => {
    if (productInfo && count < productInfo?.prod_qty) {
      setCount(count + 1);
    }
  };
  const countDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  // handles selected value changes
  const handleSelectChange = (value: any) => {
    setSelectedValue(value);
  };
  const handleReportTextChange = (event: { target: { name: any; value: any } }) => {
    let inputValue = event.target.value;
    setReportText(inputValue);
  };

  const handlePaymentCheckout = () => {
    if (guestUser === true) {
      setGusetUserSignOutShow(true);
    } else {
      navigate('/payment', {
        state: {
          product_info: productInfo,
          quantity: count,
          customer_created: customer,
        },
      });
    }
  };

  // handles report product api input params
  const reportProductFunction = () => {
    if (selectedValue) {
      const params = {
        prod_id: id,
        reason_id: selectedValue,
        tell_usmore: reportText,
      };
      dispatch(reportProduct(params));
    }
  };

  // handle modal to show signout if the user is guest
  const signoutGuestConfirmationClose = () => {
    setGusetUserSignOutShow(false);
  };

  // handle confrm guest logout and navigate to login
  const logoutGuestUser = () => {
    setGusetUserSignOutShow(false);
    navigate('/signin');
    if (window.zE) {
      window.zE('messenger:set', 'cookies', false);
      // window.zE('messenger', 'hide');
    }
    // window.location.reload();
  };
  const handleselectedMessage = (value: any) => {
    setSelectedMsg(value);
  };
  const handleView = () => {
    setViewChat(!viewChat);
    setQuestionAsked([]);
  };

  const handleSendMsg = () => {
    dispatch(
      productMessageSend({
        question_id: selectedMsg,
        prod_id: id,
      }),
    );
  };

  // This will go back to the previous page in the browser history
  const handleGoBack = () => {
    window.history.back();
  };

  // handle to copy the number to clipboard
  const handleCopyPhoneNo = async () => {
    // Check if there is an extracted number
    if (vendorInfo.business_phoneno) {
      try {
        await navigator.clipboard.writeText(vendorInfo.business_phoneno);
        toast.success('Number copied to clipboard');
      } catch (err) {
        console.error('Failed to copy number to clipboard', err);
      }
    }
  };

  return (
    <div className="inner-layout has-filter">
      {load ? (
        <div className="spinner-outer d-flex justify-content-center">
          <div className=" align-items-center justify-content-center d-flex">
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901' }} role="status"></div>
          </div>
        </div>
      ) : (
        <>
          <Container fluid className="page-header">
            <Container className="d-flex align-items-center justify-content-between header-inner">
              <Col md="6" className="d-flex align-items-center">
                <div className="vendor-title d-flex align-items-center">
                  <div className="vendor-dtls p-0">
                    <h2>{productInfo?.prod_name}</h2>
                    <div className="item-cat-wrap d-flex">
                      {productInfo?.category_id === categoryIds.PRODUCTS ? (
                        <Category category="Products" item={productInfo?.subcategory_name} products />
                      ) : productInfo?.category_id === categoryIds.SERVICES ? (
                        <Category category="Services" item={productInfo?.subcategory_name} services />
                      ) : productInfo?.category_id === categoryIds.FOOD ? (
                        <Category category="Food" item={productInfo?.subcategory_name} food />
                      ) : productInfo?.category_id === categoryIds.THINGSTODO ? (
                        <Category category="Thingstodo" item={productInfo?.subcategory_name} thingstodo />
                      ) : productInfo?.category_id === categoryIds.CLASSIFIEDS ? (
                        <Category category="Classifieds" item={productInfo?.subcategory_name} classifieds />
                      ) : (
                        <></>
                      )}
                      <div className="action-btns d-flex">
                        {vendorInfo.findus_link && (
                          <RWebShare
                            data={{
                              text: `Price: ${productInfo?.prod_price}\n Description: ${productInfo?.prod_description}`,
                              url: vendorInfo?.findus_link,
                              title: productInfo?.prod_name,
                            }}
                            onClick={() => console.log('shared successfully!')}
                          >
                            <span>
                              <ActionBtn tooltip="Share">
                                <ShareIc />
                              </ActionBtn>
                            </span>
                          </RWebShare>
                        )}
                        <ActionBtn onClick={handleShow} tooltip="Flag">
                          <FlagIc />
                        </ActionBtn>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6" className="text-md-end btn-wrap">
                <Button onClick={handleGoBack} variant="secondary">
                  {Strings.Profile.Back}
                </Button>
              </Col>
            </Container>
          </Container>
          <Container fluid>
            <Container className="content-area">
              <Row>
                <Col lg="8" className="product-slider">
                  <Carousel indicators={false} controls={productImage !== null && productImage.length === (0 || 1) ? false : true}>
                    {productImage &&
                      productImage.map((data, key) => (
                        <Carousel.Item>
                          {data?.image_path && youtubePatterns(data?.image_path) && (
                            <YouTube videoId={getIdfromYoutubeLink(data?.image_path)} opts={{ height: '500px', width: '100%' }} />
                          )}
                          {data?.image_path && !youtubePatterns(data?.image_path) && <img src={data?.image_path} alt="" />}
                        </Carousel.Item>
                      ))}
                  </Carousel>
                  <div className="prod-price">${productInfo?.prod_price}</div>
                  <h3>What's Great About This!</h3>
                  <p>{productInfo?.whats_great}</p>
                  <h3>Description</h3>
                  <p>{productInfo?.prod_description}</p>
                  {productInfo?.prod_availability && productInfo?.category_id === categoryIds.SERVICES && (
                    <div className="prod-time">
                      Hours
                      <br />
                      <span>{productInfo?.prod_availability}</span>
                    </div>
                  )}
                  {productInfo?.posted_date && productInfo?.valid_till && productInfo?.category_id === categoryIds.CLASSIFIEDS && (
                    <div className="prod-time">
                      Posted Date:
                      <span style={{ paddingRight: 25 }}> {moment(productInfo?.posted_date).format('MMM D, YYYY')}</span>
                      {/* <br/> */}
                      Valid Till:
                      <span> {moment(productInfo?.valid_till).format('MMM D, YYYY')}</span>
                    </div>
                  )}
                  {productInfo?.posted_date && productInfo?.category_id === categoryIds.THINGSTODO && (
                    <>
                      <div className="prod-time">
                        {/* {productInfo.delivery_type_ids && productInfo.delivery_type_ids.map((data) => <ServiceItem type={data} />)} */}
                        Event Date & Time:
                        <span>{moment(productInfo?.posted_date).format('MMM D, YYYY · h:mm A')}</span>
                      </div>
                    </>
                  )}
                  {productInfo?.category_id === categoryIds.PRODUCTS || productInfo?.category_id === categoryIds.FOOD || productInfo?.category_id === categoryIds.THINGSTODO ? (
                    <>
                      <div style={{ marginBottom: 15 }}>Quantity</div>
                      <NBInputQty value={count} increment={countIncrement} decrement={countDecrement} id="quantity" name="quantity" />
                    </>
                  ) : (
                    <></>
                  )}
                  {productInfo.delivery_type_ids && productInfo.delivery_type_ids.length !== 0 && (
                    <>
                      <h3>Additional Info</h3>
                      <div className="service-types d-flex">{productInfo.delivery_type_ids && productInfo.delivery_type_ids.map((data) => <ServiceItem type={data} />)}</div>
                    </>
                  )}
                  {productInfo.safetradeavailable === error_code.Code_1 && <div className='safe-trade d-flex align-items-center'><Link to='https://safetradespots.com/' target='_blank' rel='noopener noreferrer' style={{ color: 'black' }}>Safe Trade Available</Link></div>}
                  {productInfo.category_id && productInfo?.category_id === categoryIds.SERVICES && (
                    <div className="ride-type">
                      <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                        <NavLink to="https://www.google.com/maps/" style={{ color: 'black' }} target="_blank" rel="noopener noreferrer">
                          <Button variant="light" className="need-ride" id="tbg-radio-2">
                            Need a ride there?
                          </Button>
                        </NavLink>
                        <NavLink to="https://www.uber.com/in/en/ride/" style={{ color: 'black' }} target="_blank" rel="noopener noreferrer">
                          <Button variant="light" className="uber" id="tbg-radio-2">
                            Uber
                          </Button>
                        </NavLink>
                        <NavLink to="https://www.lyft.com/" style={{ color: 'black' }} target="_blank" rel="noopener noreferrer">
                          <Button variant="light" className="lyft" id="tbg-radio-2">
                            Lyft
                          </Button>
                        </NavLink>
                      </ToggleButtonGroup>
                    </div>
                  )}
                  <Button onClick={handlePaymentCheckout} variant="primary">
                    {productInfo?.category_id === categoryIds.SERVICES ? (
                      <>Book Service</>
                    ) : productInfo?.category_id === categoryIds.THINGSTODO ? (
                      <>Book Now</>
                    ) : productInfo?.category_id === categoryIds.CLASSIFIEDS ? (
                      <>Apply</>
                    ) : (
                      <>Checkout</>
                    )}
                  </Button>
                </Col>
                <Col lg="4">
                  <div className="contact-vendor mt-0 mb-4">
                    <h3 className="pt-0">{vendorInfo?.business_name}</h3>
                    <div className="address-vendor">
                      <p className="title">{Strings.VendorDetails.Address}</p>
                      <p className="address-txt">
                        {vendorInfo.address1 ? `${vendorInfo?.address1.trim()},` : ''} {vendorInfo.city ? `${vendorInfo?.city.trim()},` : ''}
                        {vendorInfo.country ? `${vendorInfo?.country.trim()},` : ''} {vendorInfo.statename ? `${vendorInfo?.statename.trim()}  ` : ''}
                        {vendorInfo.zip ? ` ${vendorInfo?.zip.trim()}` : ''}
                      </p>
                    </div>
                    <div className="other-dtls-vendor">
                      {vendorInfo.business_phoneno && (
                        <span onClick={handleCopyPhoneNo} className="phone d-inline-block">
                          {phoneFormat(vendorInfo?.business_phoneno)}
                        </span>
                      )}
                      {vendorInfo.findus_link && (
                        <NavLink to={vendorInfo?.findus_link} target="_blank" style={{ color: 'black' }}>
                          <span className="website d-inline-block">Find Us on</span>
                        </NavLink>
                      )}
                    </div>
                    {vendorInfo?.sellerpolicy_text && (
                      <>
                        <h3 className="mt-4">{Strings.VendorDetails.SellerPolicy}</h3>
                        <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={160}>
                          {vendorInfo?.sellerpolicy_text && <p className="common-content">{vendorInfo?.sellerpolicy_text}</p>}
                        </Scrollbars>
                      </>
                    )}
                    {vendorInfo?.sellerpolicy_link && (
                      <>
                        <h3 className="mt-4">{Strings.VendorDetails.SellerPolicy}</h3>
                        <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={160}>
                          {vendorInfo?.sellerpolicy_link && (
                            <NavLink to={vendorInfo?.sellerpolicy_link} target="_blank" className="common-content">
                              {vendorInfo?.sellerpolicy_link}
                            </NavLink>
                          )}
                        </Scrollbars>
                      </>
                    )}
                  </div>
                  <AppsAdRight />
                </Col>
              </Row>
            </Container>
            {/* Report Modal */}
            <Modal show={show} onHide={handleClose} centered backdrop="static" className="report">
              <Modal.Header>
                <Modal.Title>{Strings.VendorDetails.Report.Title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="pb-5">
                <p className="mb-4">{Strings.VendorDetails.Report.Description}</p>
                <NBSelect
                  id="select"
                  name="select"
                  options={reportReasons}
                  handleSelectChange={handleSelectChange}
                  value={selectedValue}
                  label={Strings.VendorDetails.Report.Label1}
                />
                <NBInputGroup
                  id="Tellusmore"
                  name="Tellusmore"
                  label={Strings.VendorDetails.Report.Label2}
                  as="textarea"
                  maxLength={200}
                  rows={5}
                  value={reportText}
                  onChange={handleReportTextChange}
                />
                <Col className="button-nav">
                  <Button variant="secondary" onClick={handleClose}>
                    {Strings.VendorDetails.Report.Btn1}
                  </Button>
                  <Button variant="primary" onClick={reportProductFunction}>
                    {Strings.VendorDetails.Report.Btn2}
                  </Button>
                </Col>
              </Modal.Body>
            </Modal>
            <Modal show={gusetUserSignOutShow} onHide={signoutGuestConfirmationClose} centered backdrop="static">
              <Modal.Body className="otp-verify">
                <h1 className="text-center">{Strings.Login.Title}</h1>
                <span className="text-center d-block pt-3">
                  Sign in to continue.
                  <br />
                </span>
                <div className="btn-wrap d-flex pt-4">
                  <Button onClick={signoutGuestConfirmationClose} variant="secondary">
                    {Strings.Header.DropDownMenu.signOut.Btn2}
                  </Button>
                  <Button onClick={logoutGuestUser} variant="primary">
                    {Strings.Header.DropDownMenu.signOut.Btn1}
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
            {!guestUser && (
              <Chat
                handleView={handleView}
                questionList={questionList}
                productMessageHistory={productMessagesHistory}
                changeSelectedMsg={handleselectedMessage}
                selectedMsg={selectedMsg}
                questionsAsked={questionAsked}
                send={handleSendMsg}
                sendBtnActive={!selectedMsg}
                viewChat={viewChat}
              />
            )}
          </Container>
        </>
      )}
    </div>
  );
};

export default ProductDetail;
