/**
 * @file  PaymentCard.tsx
 * @brief  Payment Card Stripe
 * @date   Oct, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useNavigate } from 'react-router-dom';
import { productMakeOrder } from '../store/actions/productAction';
import { useAppDispatch } from '../hooks/index';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';

const PaymentCard = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const productInfo = props.productInfo;
  const quantity = props.quantity;
  const setLoad = props.setLoad
  const load = props.load
  const total = props.total
  
  // handle payment submit 
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    setLoad(true)
    event.preventDefault();

    if (!stripe && !elements) {
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_API_URL}/home`,
      },
      redirect: 'if_required',
    });

    if (error) {
      toast.error(error.message);
      setLoad(false)
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      const payload = {
        prod_id: productInfo?.prod_id,
        qty: quantity,
        amount :  props?.total,
        paymentIntentId: paymentIntent?.id,
        isZeroPayment: props?.total === 0 ? 1 : 0,
      }
      dispatch(productMakeOrder(payload))
    } else {
      setLoad(false)
      toast.error(paymentIntent?.status);
    }
  };

  // handle cancel button
  const handleCancel = () => {
    navigate('/payment', {
      state: {
        product_info: productInfo,
        quantity: quantity,
      },
    });
  };
  return (
    <div>
      <PaymentElement options={{ layout: 'tabs', terms: { card: 'always' } }} />
      <Col className="button-nav" style={{ paddingTop: 20 }}>
        <Button onClick={handleCancel} variant="secondary">
          Cancel
        </Button>
        <Button disabled={!stripe || load}  onClick={handleSubmit} variant="primary">
          {load ? <Spinner animation="border" size="sm" variant="light" />:
          <>Pay ${total.toFixed(2)}</>
          }
        </Button>
      </Col>
    </div>
  );
};

export default PaymentCard;
