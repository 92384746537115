export const ENTER_FIRST_NAME = 'Enter First Name.';
export const ENTER_LAST_NAME = 'Enter Last Name.';
export const ENTER_EMAIL = 'Enter Email Address.';
export const ENTER_PHONE_NUMBER = 'Enter Mobile Number.';
export const ENTER_VALID_EMAIL = 'Enter a valid Email address.';
export const ENTER_MESSSAGE = 'Enter Message.';

export const ENTER_ADDRESS = 'Enter Address.';
export const ENTER_CITY = 'Enter City.';
export const ENTER_STATE = 'Enter State.';
export const ENTER_ZIP = 'Enter Zip Code.';

export const SPECIALCHARACTER_ERROR = 'Special Characters are not allowed.';
export const NUMBER_ERROR = 'Numbers are not allowed.';
export const ENTER_VALID_PHONE_NUMBER = 'Enter a valid Mobile Number.';
export const ENTER_VALID_PASSWORD = 'Password should contain 6-15 characters, at least one upper case letter and one special character.';
export const INVALID_CODE = "Invalid code."
export const INVALID_ZIP_CODE = 'Enter your Zip Code.';
export const ZIP_CODE_VALID = 'Enter a valid Zip Code.';
export const LOCATION_PERMISSION_DENIED = "Enable location permission in browser to view your NearBuys offers."
export const DEVICE_SESSION_LOGOUT = 'You will be signed out from here as your sign in has been detected on another device.';

export const ENTER_REFUND_REASON = 'Enter reason for refund.';
export const CONFIRM_ALERT = 'Are you sure want to proceed?';
export const ABOUT_US_CONFIRM_ALERT = 'You will be redirected to another website. Do you wish to continue?';

export const ENTER_CURRENT_PWD = 'Enter the Current Password.'
export const ENTER_NEW_PWD = 'Enter the New Password.'
export const RE_ENTER_PWD = 'Enter the Confirm New Password'
export const CONFIRM_NEW_PWD = 'New Password and re-entered Confirm New Password does not match.'
export const MAX_VALUE_ERROR = 'Max.Price should be greater than Min.price.'
